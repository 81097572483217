import type { TFunction, I$W, Experiments } from '@wix/yoshi-flow-editor';
import { context } from '../../context/RootContext';
import { LABELS_LIMIT } from '../../api/consts';
import {
  DISHES_WIDGET_COMPONENT_IDS,
  UNAVAILABLE_DISHES_COMPONENT_IDS,
} from '../../appConsts/blocksIds';
import { isItemValidInCart, getAltText, buildImgSrc } from './utils';
import { DISHES_WIDGET_STATES, DISH_STATES } from '../../types/businessTypes';
import type { ItemData } from '../../types/item';
import type { DishesWidgetState } from '../../types/businessTypes';
import { getVariantWithMinimalPrice } from '../../utils/priceVariantsUtils';
import { ADD_TO_CART_ERRORS } from '../../services/cartService';
import { LiveSiteClickFulfillmentOrigin } from '@wix/restaurants-bi';
import { dispatchState } from 'root/states/DispatchState';
import { getAreNotEnoughModifiersOfMandatoryModifierGroupInStock } from 'root/utils/itemModalControllerUtils';
import { menusState } from 'root/states/MenusState';
import { openDispatchModal } from 'root/utils/utils';
import { availabilityStatusKeys } from 'root/availabilityStatusKeys';
import { getAvailabilityStatusProps } from 'root/utils/menusUtils';
import { when, autorun } from 'mobx';
import { navigationMenuState } from 'root/states/NavigationMenuState';
import { cartState } from 'root/states/cartState';
import { itemState } from 'root/states/itemsState';
import { SPECS } from 'root/appConsts/experiments';

const DISH_STATE_TO_IDS = {
  [DISH_STATES.regular]: DISHES_WIDGET_COMPONENT_IDS,
  [DISH_STATES.unavailable]: UNAVAILABLE_DISHES_COMPONENT_IDS,
};

const SLIDING_WINDOW_SIZE = 10;

const getDishesState = (
  itemIds: string[],
  isTruncated: boolean,
  size: number,
  isFetching: boolean
): DishesWidgetState => {
  let dishState =
    itemIds.length > 0 ? DISHES_WIDGET_STATES.dishes : DISHES_WIDGET_STATES.dishesEmpty;
  if ((isTruncated && size === 0) || (isFetching && itemIds.length !== 0)) {
    dishState = DISHES_WIDGET_STATES.loading;
  }
  return dishState;
};

export class DishesController {
  private didInitItemState = {} as Record<string, Record<keyof typeof DISH_STATES, boolean>>;
  constructor(
    private $w: I$W,
    private t: TFunction,
    private isMobile: boolean,
    private experiments: Experiments,
    private timezone: string,
    private locale: string,
    private isMemberLoggedIn?: boolean
  ) {}

  async openDishModal(itemData: ItemData, menuId: string, sectionId: string) {
    const menu = menusState.getMenu(menuId);
    const { isMenuOfItemAvailable, hasNextAvailability, text, shouldCollapseAvailabilityStatus } =
      getAvailabilityStatusProps({
        menu,
        locale: this.locale,
        timezone: this.timezone,
        t: this.t,
        keys: availabilityStatusKeys.itemModal,
      });

    const dishModalRes = await context.ModalService?.openDishModal({
      item: itemData,
      cartService: context.CartService,
      biReporterService: context.biReporterService,
      operation: dispatchState.currentOperation,
      canAcceptOrders: dispatchState.isSelectedDispatchAvailable(),
      menuId,
      sectionId,
      fedopsLogger: context.fedopsLogger,
      availabilityStatusProps: {
        isMenuOfItemAvailable,
        text,
        shouldCollapseAvailabilityStatus,
        hasNextAvailability,
        dispatchType: dispatchState.selectedDispatchType,
      },
      openDispatchModal: async () => this.openDispatchModal(itemData, menuId, sectionId),
    });

    const { data: dataPromise, additionaldata } = dishModalRes ?? {};
    const cartLineItemsKey = `${menuId}_${sectionId}_${itemData._id}`;
    const cartLineItems = cartState.cartLineItems.get(cartLineItemsKey) ?? [];
    additionaldata &&
      cartState.cartLineItems.set(cartLineItemsKey, [...cartLineItems, additionaldata]);

    const data = await dataPromise;

    if (
      data?.error === ADD_TO_CART_ERRORS.MIXED_CART ||
      data?.error === ADD_TO_CART_ERRORS.MIXED_CART_OPERATION
    ) {
      const content =
        data?.error === ADD_TO_CART_ERRORS.MIXED_CART
          ? this.t('cart.mixed-vertical-cart-error.content')
          : this.t('cart.mixed-cart-by-operation-error.content');

      context.ModalService?.openErrorModal({
        title: this.t('cart.mixed-vertical-cart-error.title'),
        content,
        closeButtonLabel: this.t('cart.mixed-vertical-cart-error.button'),
      });
      cartState.cartLineItems.set(cartLineItemsKey, cartLineItems);
      return;
    }

    if (data && !data.cartItem) {
      cartState.cartLineItems.set(cartLineItemsKey, cartLineItems);
      context.ModalService?.openErrorModal({
        title: this.t('addToCart.error-modal.general-error.title'),
        content: this.t('addToCart.error-modal.general-error.content'),
        closeButtonLabel: this.t('addToCart.error-modal.general-error.button'),
      });
    }
  }

  async openDispatchModal(itemData: ItemData, menuId: string, sectionId: string) {
    const self = this;
    openDispatchModal({
      onSave: async ({ dispatchType, dispatchInfo, operationId, isLocationChanged }) => {
        const isCurrentOperationChanged = dispatchState.operationId !== operationId;
        isCurrentOperationChanged && dispatchState.setCurrentOperationId(operationId);
        dispatchState.setIsLocationSelected(true);
        dispatchState.update(dispatchType, dispatchInfo);
        if (isLocationChanged) {
          await context.CartService?.clearCart();
        }
        context.CartService?.setShippingDetails(dispatchState.getShippingDetails());
        await menusState.updateAvailabilityStatus(
          dispatchState.currentOperationId,
          dispatchInfo,
          dispatchType
        );

        if (!isCurrentOperationChanged) {
          self.openDishModal(itemData, menuId, sectionId);
        }
      },
      context,
      dispatchState: dispatchState.state,
    });
  }

  setItems(items: string[], size: number) {
    const itemIds = items.filter((id) => itemState.itemMap.has(id));
    this.$w(DISHES_WIDGET_COMPONENT_IDS.repeaterItems).data = itemIds.slice(0, size).map((_id) => ({
      _id,
      ...itemState.itemMap.get(_id),
    }));
  }

  initItemStateInitializationMap(itemIds: string[]) {
    this.didInitItemState = itemIds.reduce((acc, id) => {
      acc[id] = {
        [DISH_STATES.regular]: false,
        [DISH_STATES.unavailable]: false,
      };
      return acc;
    }, {} as Record<string, Record<keyof typeof DISH_STATES, boolean>>);
  }

  init(
    isEditor: boolean,
    menuId: string,
    sectionId: string,
    isTruncated: boolean,
    size: number,
    itemIds: string[]
  ) {
    const menuState = menusState.getMenu(menuId);
    const dishesWidgetState = getDishesState(itemIds, isTruncated, size, itemState.isLoading);
    this.switchState(dishesWidgetState);
    this.initItemStateInitializationMap(itemIds);

    if (isEditor) {
      this.restoreItemCounterInEditor(menuId, sectionId);
    }

    const onItemInteraction = (itemData: ItemData, amount?: string | number) => {
      const isItemInStock = !!itemData.orderSettings?.inStock;
      context.biReporterService?.reportOloLiveSiteClickOnItemBiEvent({
        itemName: itemData.name,
        itemId: itemData.id,
        menuId,
        sectionId,
        minItemPrice: Number(amount || 0),
        operationId: dispatchState.currentOperationId,
        isMenuItemAvailable: menusState.getMenu(menuId)?.isAvailable ?? true,
        isItemInStock,
        operationGroup: dispatchState.currentOperation?.operationGroupId,
        locationId: dispatchState.currentOperation?.locationId,
        isDefaultLocation: dispatchState.currentOperation?.locationDetails?.default,
      });
      const enableMultiLocation = this.experiments.enabled(SPECS.enableMultiLocation);
      const isLocationSelected = !(
        dispatchState.isMultiLocation && !dispatchState.isLocationSelected
      );
      const shouldOpenDishModal =
        (!!dispatchState.dispatchInfo.address ||
          !dispatchState.availableDispatchTypesForCurrentLocation?.length) &&
        (!enableMultiLocation || isLocationSelected);
      if (shouldOpenDishModal) {
        this.openDishModal(itemData, menuId, sectionId);
      } else {
        context.biReporterService?.reportOloLiveSiteClickOnFulfillmentBiEvent({
          origin: LiveSiteClickFulfillmentOrigin.CLICK_ITEM_NO_ADDRESS,
          dispatchType: dispatchState.selectedDispatchType,
          isMemberLoggedIn: this.isMemberLoggedIn,
        });
        this.openDispatchModal(itemData, menuId, sectionId);
      }
    };

    this.$w(DISHES_WIDGET_COMPONENT_IDS.repeaterItems).onItemReady(
      ($item: I$W, itemData: ItemData) => {
        const areNotEnoughModifiersOfMandatoryModifierGroupInStock =
          getAreNotEnoughModifiersOfMandatoryModifierGroupInStock(itemData.modifierGroups);
        const isInStockItem =
          (itemData.orderSettings?.inStock &&
            !areNotEnoughModifiersOfMandatoryModifierGroupInStock) ??
          true;
        const amount = itemData.priceVariants
          ? getVariantWithMinimalPrice(itemData.priceVariants.variants || [])?.priceInfo.price
          : itemData.price.amount;

        $item(DISHES_WIDGET_COMPONENT_IDS.itemContainer).onClick(() =>
          onItemInteraction(itemData, amount)
        );

        const dishMultiState = $item(DISHES_WIDGET_COMPONENT_IDS.dishStateMultiStateBox);
        autorun(() => {
          const hasNextAvailableTime = !!menuState?.nextAvailableTimeslot;
          const isMenuOfItemAvailable = menuState?.isAvailable ?? true;
          const isItemUnavailable = !isMenuOfItemAvailable && !hasNextAvailableTime;
          const dishState =
            isInStockItem && !isItemUnavailable ? DISH_STATES.regular : DISH_STATES.unavailable;

          dishMultiState.changeState(dishState);
          const formattedPrice = context.priceFormatter(Number(amount));
          const formattedPriceText = this.getItemPriceText(
            isInStockItem,
            isItemUnavailable,
            formattedPrice
          );
          $item(DISH_STATE_TO_IDS[dishState].itemPrice).text = formattedPriceText;

          if (!this.didInitItemState[itemData.id][dishState]) {
            this.initItem(
              DISH_STATE_TO_IDS[dishState],
              $item,
              itemData,
              isEditor,
              menuId,
              sectionId,
              Number(amount),
              onItemInteraction
            );
            this.didInitItemState[itemData.id][dishState] = true;
          }
        });

        const itemIdsBySize = itemIds.filter((id) => itemState.itemMap.has(id)).slice(0, size);

        const isTenthToLastItem =
          itemData.id === itemIdsBySize[Math.max(0, size - SLIDING_WINDOW_SIZE)];

        const shouldLoadMoreOnScroll = isTruncated && isTenthToLastItem && size < itemIds.length;

        if (shouldLoadMoreOnScroll) {
          dishMultiState.onViewportEnter(() => {
            if (!navigationMenuState.isNavigating && !itemState.isLoading) {
              this.setItems(itemIds, itemIds.length);
            }
          });
        }
      }
    );

    when(
      () => !itemState.isLoading,
      async () => {
        this.setItems(itemIds, size);
        const dishesState = getDishesState(itemIds, isTruncated, size, false);
        this.switchState(dishesState);

        if (dishesState === DISHES_WIDGET_STATES.loading) {
          when(
            () => {
              const { selectedSectionId, sectionScrollList, isNavigating } = navigationMenuState;
              const isInViewPort = sectionScrollList[sectionId]?.isInViewPort;
              const isNavigatingToLowerSection =
                !this.isMobile &&
                isNavigating &&
                sectionScrollList[selectedSectionId].index > sectionScrollList[sectionId].index;
              const shouldLoadMoreItems =
                isInViewPort &&
                (!this.isMobile || !isNavigating || selectedSectionId === sectionId);

              return shouldLoadMoreItems || isNavigatingToLowerSection;
            },
            () => {
              this.setItems(itemIds, itemIds.length);
              this.switchState(getDishesState(itemIds, isTruncated, itemIds.length, false));
            }
          );
        }
      }
    );
  }

  initItem(
    componentIds: typeof DISHES_WIDGET_COMPONENT_IDS | typeof UNAVAILABLE_DISHES_COMPONENT_IDS,
    // @ts-expect-error
    $item,
    itemData: ItemData,
    isEditor: boolean,
    menuId: string,
    sectionId: string,
    price: number,
    onItemInteraction: (itemData: ItemData, amount?: string | number) => void
  ) {
    const title = $item(componentIds.itemTitle);
    title.text = itemData?.name || '';
    if (!itemData?.name) {
      title.hide();
      this.isMobile && title.collapse();
    } else {
      title.collapsed && title.expand();
      title.hidden && title.show();
    }

    if (!isEditor) {
      const titleWrapper = $item(componentIds.itemTitleWrapper);
      if (!itemData.name) {
        titleWrapper.hide();
        this.isMobile && titleWrapper.collapse();
      } else {
        titleWrapper.collapsed && titleWrapper.expand();
        titleWrapper.hidden && titleWrapper.show();
      }
      titleWrapper.onKeyPress((e: KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onItemInteraction(itemData, price);
        }
      });
      titleWrapper.accessibility = {
        ariaAttributes: {
          haspopup: 'true',
        },
        role: 'button',
        tabIndex: 0,
      };
    }

    const description = $item(componentIds.itemDescription);
    description.text = itemData?.description || '';
    if (!itemData?.description) {
      description.hide();
      this.isMobile && description.collapse();
    } else {
      description.collapsed && description.expand();
      description.hidden && description.show();
    }

    const image = $item(componentIds.itemImage);

    if (itemData.image?.url) {
      image.src = (itemData.image && buildImgSrc(itemData.image)) ?? '';
      image.alt = getAltText({ itemName: itemData.name, t: this.t });
    } else {
      image.collapse();
    }

    const hasLabels = itemData.labels?.length > 0;

    const labelContainer = $item(componentIds.labelContainer);
    if (hasLabels) {
      labelContainer.collapsed && labelContainer.expand();
      labelContainer.hidden && labelContainer.show();
      this.initLabels(itemData, $item, componentIds.label, componentIds.additionalLabelsCounter);
    } else {
      this.isMobile && labelContainer.collapse();
      labelContainer.hide();
    }

    const cartLineItemsKey = `${menuId}_${sectionId}_${itemData.id}`;
    autorun(() => {
      const cartItems = cartState.cartLineItems.get(cartLineItemsKey);
      const hasItemInCart = isItemValidInCart(cartItems);
      const itemCounter = $item(componentIds.itemCounter);
      const itemCounterValue = $item(componentIds.itemCounterValue);
      if (hasItemInCart) {
        itemCounter.collapsed && itemCounter.expand();
        itemCounter.hidden && itemCounter.show();
      } else {
        itemCounter.collapse();
        itemCounter.hide();
      }
      itemCounterValue.text = (
        cartItems?.reduce(
          (previousValue, currentValue) => previousValue + (currentValue?.quantity || 0),
          0
        ) || 0
      ).toString();
    });
  }

  initLabels(
    itemData: ItemData,
    $item: I$W,
    getLabelElement: (idx: number) => string,
    additionalLabelsCounterId: string
  ) {
    for (let i = 0; i < LABELS_LIMIT; i++) {
      const currentLabel = itemData.labels?.[i];
      const currentLabelElement = $item(getLabelElement(i + 1));

      if (currentLabel && currentLabel.icon?.url) {
        currentLabelElement.collapsed && currentLabelElement.expand();
        currentLabelElement.src = currentLabel.icon?.url;
        currentLabelElement.alt = currentLabel.name;
        currentLabelElement.accessibility = {
          ariaAttributes: {
            label: currentLabel.name,
          },
        };
      } else {
        currentLabelElement.collapse();
      }
    }

    const numOfAdditionalLabels = itemData.labels.length - LABELS_LIMIT;
    const showLabelCounter = itemData.labels.length > LABELS_LIMIT;
    const PLUS_SIGN = '+';
    const additionalLabelsCounterIdElement = $item(additionalLabelsCounterId);
    additionalLabelsCounterIdElement.text = showLabelCounter
      ? `${PLUS_SIGN}${numOfAdditionalLabels}`
      : '';
    if (showLabelCounter) {
      additionalLabelsCounterIdElement.collapsed && additionalLabelsCounterIdElement.expand();
      additionalLabelsCounterIdElement.hidden && additionalLabelsCounterIdElement.show();
    } else {
      additionalLabelsCounterIdElement.collapse();
      additionalLabelsCounterIdElement.hide();
    }
  }

  switchState(dishState: DishesWidgetState) {
    const multiStateBox = this.$w(DISHES_WIDGET_COMPONENT_IDS.dishesWidgetMultiStateBox);
    multiStateBox.changeState(dishState);
  }

  getItemPriceText(isInStockItem: boolean, isItemUnavailable: boolean, formattedPrice: string) {
    let itemPriceText;
    if (isItemUnavailable) {
      itemPriceText = this.t('menu_olo.itemUnavailable.itemPrice', {
        price: formattedPrice,
      });
    } else if (!isInStockItem) {
      itemPriceText = this.t('menu_olo.OutOfStock.itemPrice', {
        price: formattedPrice,
      });
    } else {
      itemPriceText = formattedPrice;
    }

    return itemPriceText;
  }

  deleteItemCounterInEditor(menuId: string) {
    this.$w(DISHES_WIDGET_COMPONENT_IDS.repeaterItems).forEachItem(
      ($item: I$W, itemData: ItemData) => {
        const isInStockItem = itemData.orderSettings?.inStock ?? true;
        const isMenuOfItemAvailable = menusState.getMenu(menuId)?.isAvailable ?? true;
        const dishesWidgetIds =
          isInStockItem && isMenuOfItemAvailable
            ? DISHES_WIDGET_COMPONENT_IDS
            : UNAVAILABLE_DISHES_COMPONENT_IDS;
        $item(dishesWidgetIds.itemCounter).delete();
      }
    );
  }

  restoreItemCounterInEditor(menuId: string, sectionId: string) {
    this.$w(DISHES_WIDGET_COMPONENT_IDS.repeaterItems).forEachItem(
      ($item: I$W, itemData: ItemData) => {
        const isInStockItem = itemData.orderSettings?.inStock ?? true;
        const isMenuOfItemAvailable = menusState.getMenu(menuId)?.isAvailable ?? true;
        const dishesWidgetIds =
          isInStockItem && isMenuOfItemAvailable
            ? DISHES_WIDGET_COMPONENT_IDS
            : UNAVAILABLE_DISHES_COMPONENT_IDS;

        const shouldRestoreBadge = this.hasItemsInCart(menuId, sectionId, itemData.id);
        shouldRestoreBadge && $item(dishesWidgetIds.itemCounter).restore();
      }
    );
  }

  hasItemsInCart(menuId: string, sectionId: string, itemId: string) {
    const cartLineItemsKey = `${menuId}_${sectionId}_${itemId}`;
    const cartItems = cartState.cartLineItems.get(cartLineItemsKey);

    const hasItemInCart = isItemValidInCart(cartItems);
    return hasItemInCart;
  }

  setEmptyState() {
    this.$w(DISHES_WIDGET_COMPONENT_IDS.dishesWidgetMultiStateBox).changeState(
      DISHES_WIDGET_STATES.dishesEmpty
    );
    this.$w(DISHES_WIDGET_COMPONENT_IDS.dishEmptyStateTitle).text = this.t(
      'menu_olo.emptyState.title'
    );
    this.$w(DISHES_WIDGET_COMPONENT_IDS.dishEmptyStateSubtitle).text = this.t(
      'menu_olo.emptyState.subTitle'
    );
  }
}
